<template>
  <ln-container wrap color-accent sm=12 md=10 lg=10 xl=10 row space>
    <ln-row>
      <ln-image-gallery-slideshow
          shadow
          border-radius="5"
          nav
          v-model="imageIndex"
          width="100%"
          :images="[
              'https://api.files.ln-cloud.de/image/medium/c54a4b2f-db3b-4456-9ced-c8a9005b2b46',
              'https://api.files.ln-cloud.de/image/medium/e5606928-8992-417a-b84c-505e7c329f61',
              'https://api.files.ln-cloud.de/image/medium/0f52559d-d00c-40a8-a714-68d2f30cc647',
              'https://api.files.ln-cloud.de/image/medium/e0ba90fb-fad3-492b-9c11-de40292f9ca7',
              'https://api.files.ln-cloud.de/image/medium/f5827b7e-1d5d-4092-ad56-2cbba763e859',
              'https://api.files.ln-cloud.de/image/medium/168926f2-7d57-43f2-a365-8b3534c5e53a',
              'https://api.files.ln-cloud.de/image/small/9b05a23e-7588-4f98-a619-c628d1fecad1'
              ]">
      </ln-image-gallery-slideshow>
    </ln-row>
    <ln-row space primary-color border-radius="5" shadow>
      <ln-col v-if="imageIndex === 0" wrap justify="start">
        <ln-col space>
          <span>Die Wohnung ist komplett mit Laminatboden ausgelegt und komfortabel und gemütlich eingerichtet.</span>
        </ln-col>
        <ln-col space xl="15" align="center">
          <ln-icon>ruler-square</ln-icon><span>56 m² Wohnfläche</span>
        </ln-col>
        <ln-col space align="center">
          <ln-icon>wifi</ln-icon><span>freies WLAN</span>
        </ln-col>
      </ln-col>
      <ln-col space v-if="imageIndex === 1" primary-color wrap justify="center">
        <ln-col space align="center">
          <ln-icon>sofa</ln-icon><span>gemütliche Sitzecke</span>
        </ln-col>
        <ln-col space align="center">
          <ln-icon>television</ln-icon><span>SAT-Fernseher</span>
        </ln-col>
        <ln-col space align="center">
          <ln-icon>speaker</ln-icon><span>Stereoanlage mit CD</span>
        </ln-col>
        <ln-col space align="center">
          <ln-icon>chess-knight</ln-icon><span>diverse Gesellschaftsspiele</span>
        </ln-col>
      </ln-col>
      <ln-col space v-if="imageIndex === 2" primary-color wrap>
        <ln-col space align="center">
          <ln-icon>bunk-bed</ln-icon><span>1 Etagenbett</span>
        </ln-col>
        <ln-col space align="center">
          <ln-icon>sofa</ln-icon><span>1 Schlafcouch</span>
        </ln-col>
        <ln-col space align="center">
          <ln-icon>wardrobe</ln-icon><span>1 Schrank 1,00m</span>
        </ln-col>
      </ln-col>
      <ln-col v-if="imageIndex === 3" primary-color wrap justify="center">
        <ln-col space align="center">
          <ln-icon>bed-king</ln-icon><span>1 Doppelbett 1,80m x 2,00m</span>
        </ln-col>
        <ln-col space align="center">
          <ln-icon>wardrobe</ln-icon><span>1 Schrank 1,50m</span>
        </ln-col>
      </ln-col>
      <ln-col v-if="imageIndex === 4" primary-color wrap justify="center">
        <ln-row wrap xs="16" sm="8" md="8" lg="8" xl="8">
          <ln-col space align="center">
            <ln-icon>coffee-maker</ln-icon><span>Kaffeemaschine</span>
          </ln-col>
          <ln-col space align="center">
            <ln-icon>microwave</ln-icon><span>Mikrowelle</span>
          </ln-col>
          <ln-col space align="center">
            <ln-icon>dishwasher</ln-icon><span>Spülmaschine</span>
          </ln-col>
          <ln-col space align="center">
            <ln-icon>fridge</ln-icon><span>Kühlschrank mit Gefrierfach</span>
          </ln-col>
        </ln-row>
        <ln-row wrap xs="16" sm="8" md="8" lg="8" xl="8">
          <ln-col space align="center">
            <ln-icon>stove</ln-icon><span>Kochstelle mit Backofen und Ceranfeld</span>
          </ln-col>
          <ln-col space align="center">
            <ln-icon>toaster</ln-icon><span>Toaster, Mixer, Wasserkocher</span>
          </ln-col>
          <ln-col space align="center">
            <ln-icon>chair-school</ln-icon><span>Kinderstuhl</span>
          </ln-col>
        </ln-row>
      </ln-col>
      <ln-col v-if="imageIndex === 5" primary-color wrap justify="center">
        <ln-col space align="center">
          <ln-icon>washing-machine</ln-icon><span>Waschmaschine</span>
        </ln-col>
        <ln-col space align="center">
          <ln-icon>tumble-dryer</ln-icon><span>Trockner</span>
        </ln-col>
        <ln-col space align="center">
          <ln-icon>iron</ln-icon><span>Bügelbrett und ein Bügeleisen</span>
        </ln-col>
      </ln-col>
      <ln-col v-if="imageIndex === 6" primary-color wrap justify="center">
        <ln-col space align="center">
          <span>In unserem Garten können Sie sich auf der Terrasse erholen.</span>
        </ln-col>
        <ln-col space align="center">
          <ln-icon>grill</ln-icon><span>Grill</span>
        </ln-col>
      </ln-col>
    </ln-row>
  </ln-container>
</template>

<script>
// import LnImgGallery from "@/components/staticComponents/uiComponents/ln-img-gallery";
import {LnImageGallerySlideshow} from "@ln-cloud/ln-materials";
export default {
  name: "ApartmentPage",
  components: {LnImageGallerySlideshow },
  data() {
    return {
      imageIndex: 0
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../style/variables';
@import '../../style/grid';

.image--gallery--span {
  font-weight: bold;
}
</style>