<template>
  <ln-dialog z-index="100" xs="16" sm="16" md="16" lg="12" xl="12" v-model="dialogOpen" primary-color shadow border-radius="5">
    <ln-container>
      <ln-row space wrap>
        <ln-col align="center">
          <ln-col justify="center">
            <h4>Saisons bearbeiten</h4>
          </ln-col>
          <ln-icon @click="dialogOpen = false">close</ln-icon>
        </ln-col>
        <ln-col space xs="16" sm="16" md="8" lg="8" xl="5" wrap :key="season.id" v-for="season in occupancyPlanSeasons">
          <ln-col justify="center" align="center">{{ season.year }}<ln-icon @click="onDeleteSeason(season.id)">delete</ln-icon></ln-col>
          <ln-col xs="7" sm="7" md="7" lg="7" xl="7" justify="end">Vorsaison: </ln-col>
          <ln-col class="season_date_range" xs="8" sm="8" md="8" lg="8" xl="8" justify="start">{{ this.getReadableDate(season.preSeason.from, true) }} - {{ this.getReadableDate(season.preSeason.to, true) }}</ln-col>
          <ln-col xs="7" sm="7" md="7" lg="7" xl="7" justify="end">Nebensaison: </ln-col>
          <ln-col class="season_date_range" xs="8" sm="8" md="8" lg="8" xl="8" justify="start">{{ this.getReadableDate(season.lowSeasonOne.from, true) }} - {{ this.getReadableDate(season.lowSeasonOne.to, true) }}    </ln-col>
          <ln-col xs="7" sm="7" md="7" lg="7" xl="7" justify="end">Hauptsaison: </ln-col>
          <ln-col class="season_date_range" xs="8" sm="8" md="8" lg="8" xl="8" justify="start">{{ this.getReadableDate(season.peakSeason.from, true) }} - {{ this.getReadableDate(season.peakSeason.to, true) }}        </ln-col>
          <ln-col xs="7" sm="7" md="7" lg="7" xl="7" justify="end">Nebensaison: </ln-col>
          <ln-col class="season_date_range" xs="8" sm="8" md="8" lg="8" xl="8" justify="start">{{ this.getReadableDate(season.lowSeasonTwo.from, true) }} - {{ this.getReadableDate(season.lowSeasonTwo.to, true) }}    </ln-col>
          <ln-col xs="7" sm="7" md="7" lg="7" xl="7" justify="end">Nachsaison: </ln-col>
          <ln-col class="season_date_range" xs="8" sm="8" md="8" lg="8" xl="8" justify="start">{{ this.getReadableDate(season.postSeason.from, true) }} - {{ this.getReadableDate(season.postSeason.to, true) }}        </ln-col>
          <ln-col xs="7" sm="7" md="7" lg="7" xl="7" justify="end">Nebensaison: </ln-col>
          <ln-col class="season_date_range" xs="8" sm="8" md="8" lg="8" xl="8" justify="start">{{ this.getReadableDate(season.lowSeasonThree.from, true) }} - {{ this.getReadableDate(season.lowSeasonThree.to, true) }}</ln-col>
        </ln-col>
        <ln-spacer height="40"></ln-spacer>
        <ln-col>
          <ln-button interaction-color space shadow border-radius="5" width="100%" class="button" @click="createSeasonsDialogOpen = true">
            <span>Saison hinzufügen</span>
          </ln-button>
        </ln-col>
      </ln-row>
    </ln-container>
  </ln-dialog>
  <create-season v-model="createSeasonsDialogOpen" />
</template>

<script>
import {DELETE_SEASON} from "@/store";
import CreateSeason from "@/components/seasonAdministration/CreateSeason";

export default {
  name: "EditSeasons",
  components: {CreateSeason},
  data() {
    return {
      dialogOpen: this.modelValue,
      createSeasonsDialogOpen: false,
    }
  },
  props: {
    modelValue: {
      type: Boolean
    }
  },
  computed: {
    occupancyPlanSeasons() {
      return this.$store.getters.occupancyPlanSeasons
    }
  },
  watch: {
    dialogOpen() {
      this.$emit('update:modelValue', this.dialogOpen)
    },
    modelValue() {
      this.dialogOpen = this.modelValue
    }
  },
  methods: {
    onDeleteSeason(seasonId) {
      this.$store.dispatch(DELETE_SEASON, seasonId)
    },
    getReadableDate(date, short=false) {
      if (!date) return ''
      if (short) return `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear().toString().slice(-2)}`
      return `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear().toString()}`
    }
  }
}
</script>

<style scoped>
.season_date_range {
  padding-left: 20px;
}
</style>