import { createWebHistory, createRouter } from "vue-router";
import store from '@/store'
import LandingPage from "@/components/pageSites/LandingPage";
import LocationPage from "@/components/pageSites/LocationPage";
import OccupancyPlanPage from "@/components/pageSites/OccupancyPlanPage";
import ApartmentPage from "@/components/pageSites/ApartmentPage";
import ContactPage from "@/components/pageSites/ContactPage";
import PrivacyPage from "@/components/pageSites/PrivacyPage";
import PricesPage from "@/components/pageSites/PricesPage";

export const ANFAHRT_PAGE = '/anfahrt'

export const routes = [
    {
        path: '/',
        name: 'LandingPage',
        component: LandingPage,
        meta: {
            showMenu: true,
            showFooter: false,
            requireAuth: false,
            requireGuest: false,
            displayName: 'Home',
        },
    },
    {
        path: '/anfahrt',
        name: 'Anfahrt',
        component: LocationPage,
        meta: {
            showMenu: true,
            showFooter: false,
            requireAuth: false,
            requireGuest: false,
            displayName: 'Anfahrt',
        }
    },
    {
        path: '/unterkunft',
        name: 'Unterkunft',
        component: ApartmentPage,
        meta: {
            showMenu: true,
            showFooter: false,
            requireAuth: false,
            requireGuest: false,
            displayName: 'Unterkunft',
        }
    },
    {
        path: '/belegungsplan',
        name: 'Belegungsplan',
        component: OccupancyPlanPage,
        meta: {
            showMenu: true,
            showFooter: false,
            requireAuth: false,
            requireGuest: false,
            displayName: 'Belegungsplan',
        }
    },
    {
        path: '/preise_leistung',
        name: 'Preise_Leistung',
        component: PricesPage,
        meta: {
            showMenu: true,
            showFooter: false,
            requireAuth: false,
            requireGuest: false,
            displayName: 'Preise / Leistung',
        }
    },
    {
        path: '/datenschutz',
        name: 'Datenschutz',
        component: PrivacyPage,
        meta: {
            showMenu: false,
            showFooter: true,
            requireAuth: false,
            requireGuest: false,
            displayName: 'Datenschutz',
        }
    },
    {
        path: '/kontakt',
        name: 'Kontakt',
        component: ContactPage,
        meta: {
            showMenu: false,
            showFooter: true,
            requireAuth: false,
            requireGuest: false,
            displayName: 'Kontakt',
        }
    },
    {
        path: '/login',
        name: 'login',
        meta: {
            showMenu: false,
            showFooter: false,
            requireAuth: false,
            requireGuest: true,
            displayName: 'Login'
        },
        component: {
            beforeRouteEnter(to, from) {
                store.dispatch('PERFORM_LOGIN', from).then(() => {})
            }
        }
    },
    {
        path: '/logout',
        name: 'logout',
        meta: {
            showMenu: true,
            showFooter: false,
            requireAuth: true,
            requireGuest: false,
            displayName: 'Logout'
        },
        component: {
            beforeRouteEnter(to, from) {
                store.dispatch('PERFORM_LOGOUT', from).then(() => {})
            }
        }},
]
const router =  createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        return { top: 0 }
    }
})
router.beforeEach( (to) => {    
    to.fullPath = to.fullPath.split('#')[0]
    if (to.meta.requireAuth && !store.getters.authenticated) {
        store.dispatch('PERFORM_LOGIN', to).then(() => {})
    }
    store.dispatch('LOGIN_CALLBACK').then(() => {})
})
export default router