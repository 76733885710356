import Keycloak from 'keycloak-js'

let keycloak = Keycloak({
    url: process.env.VUE_APP_AUTH_URI,
    realm: 'Nordsee-Muehlenblick',
    clientId: 'frontend'
})

export default {
    install: (app) => {
        app.config.globalProperties.$keycloak = keycloak
    }
}