<template>
  <ln-container sm=12 md=10 lg=8 xl=6 row space>
    <ln-row justify="center" wrap class="landing-page-content">
      <ln-col space justify="center">
        <h4>Brauchen Sie Urlaub?</h4>
      </ln-col>
      <ln-col space>
        <h1>FERIENWOHNUNG MÜHLENBLICK</h1>
      </ln-col>
      <ln-col space xs=10 sm=8 md=7 lg=6 xl=6>
        <ln-button interaction-color shadow border-radius="5" @click="$router.push('/belegungsplan')" class="landing-page-button">
          Jetzt anfragen!
        </ln-button>
      </ln-col>
    </ln-row>
  </ln-container>
</template>

<script>
export default {
  name: "LandingPage"
}
</script>

<style scoped lang="scss">
@import '../../style/variables';
@import '../../style/grid';

.landing-page-content {
  @media screen and (max-width: 600px) {

    margin-top: 0;
  }
  margin-top: 60px;
}

.landing-page-button {
  width: 350px;
  height: 60px;
  background-color: white;
  filter: invert(1);
}
h1 {
  text-align: center;
}
</style>