<template>
  <ln-container sm=12 md=10 lg=10 xl=10 space wrap>
    <ln-row wrap primary-color shadow border-radius="5" >
      <ln-row wrap xl="10">
        <ln-col justify="space-between" align="center">
          <ln-row space align="center">
            <ln-col>
              <ln-button @click="updateMonthOffset(-1)" interaction-color shadow border-radius="5" width="100%" class="button">
                {{ '< ' + getMonthPerNumber(this.currentDate.getMonth() - 1) }}
              </ln-button>
            </ln-col>
            <ln-col justify="center">
              {{ getMonthPerNumber(this.currentDate.getMonth()) }} {{ currentDate.getFullYear() }}
            </ln-col>
            <ln-col justify="end">
              <ln-button @click="updateMonthOffset(1)" interaction-color shadow border-radius="5" width="100%" class="button">
                {{ getMonthPerNumber(this.currentDate.getMonth() + 1) + '  >' }}
              </ln-button>
            </ln-col>
          </ln-row>
        </ln-col>
        <ln-col>
          <ln-calendar space primary-color v-slot="{date}" :date="currentDate">
            <ln-container>
              <ln-col space @click="onDayClicked(date)" :class="mapBookingsToCalendar(date)">
                {{ date.getDate() }}
              </ln-col>
            </ln-container>
          </ln-calendar>
        </ln-col>
      </ln-row>
      <ln-row wrap xl="6">
        <ln-col wrap space primary-color>
          <ln-row wrap space>
            <ln-col>
              <p>Belegte Tage sind grau markiert.<br>
              An-/Abreisetage sind blau markiert.</p>
            </ln-col>
            <ln-col>
              <p>Für Anfragen stehen wir Ihnen gerne zur Verfügung.</p>
            </ln-col>
            <ln-col>
              <p>Persönlich am Telefon:
              <span class="font--bold">05254/69155</span><br>
              oder<br>
              per EMail unter:
              <a href="mailto:schaeffer-elsen@arcor.de">
                <span class="font--bold">schaeffer-elsen@arcor.de</span>
              </a></p>
            </ln-col>
          </ln-row>
          <ln-row space>
            <ln-col wrap>
              <ln-col v-if="$store.getters.authenticated">
                <ln-button interaction-color space @click="editSeasonsDialogOpen = true" shadow border-radius="5" width="100%" class="button">
                  <span>Saisons bearbeiten</span>
                </ln-button>
              </ln-col>
              <ln-spacer height="20px"></ln-spacer>
              <ln-col>
                <ln-col space xs="6" sm="5" md="4" lg="3" xl="5" justify="end">Vorsaison: </ln-col>
                <ln-col space>{{ this.getReadableDate(currentOccupancyPlanSeason.preSeason.from, true) }} - {{ this.getReadableDate(currentOccupancyPlanSeason.preSeason.to, true) }}</ln-col>
              </ln-col>
              <ln-col>
                <ln-col space xs="6" sm="5" md="4" lg="3" xl="5" justify="end">Nebensaison: </ln-col>
                <ln-col space>{{ this.getReadableDate(currentOccupancyPlanSeason.lowSeasonOne.from, true) }} - {{ this.getReadableDate(currentOccupancyPlanSeason.lowSeasonOne.to, true) }}</ln-col>
              </ln-col>
              <ln-col>
                <ln-col space xs="6" sm="5" md="4" lg="3" xl="5" justify="end">Hauptsaison: </ln-col>
                <ln-col space>{{ this.getReadableDate(currentOccupancyPlanSeason.peakSeason.from, true) }} - {{ this.getReadableDate(currentOccupancyPlanSeason.peakSeason.to, true) }}</ln-col>
              </ln-col>
              <ln-col>
                <ln-col space xs="6" sm="5" md="4" lg="3" xl="5" justify="end">Nebensaison: </ln-col>
                <ln-col space>{{ this.getReadableDate(currentOccupancyPlanSeason.lowSeasonTwo.from, true) }} - {{ this.getReadableDate(currentOccupancyPlanSeason.lowSeasonTwo.to, true) }}</ln-col>
              </ln-col>
              <ln-col>
                <ln-col space xs="6" sm="5" md="4" lg="3" xl="5" justify="end">Nachsaison: </ln-col>
                <ln-col space>{{ this.getReadableDate(currentOccupancyPlanSeason.postSeason.from, true) }} - {{ this.getReadableDate(currentOccupancyPlanSeason.postSeason.to, true) }}</ln-col>
              </ln-col>
              <ln-col>
                <ln-col space xs="6" sm="5" md="4" lg="3" xl="5" justify="end">Nebensaison: </ln-col>
                <ln-col space>{{ this.getReadableDate(currentOccupancyPlanSeason.lowSeasonThree.from, true) }} - {{ this.getReadableDate(currentOccupancyPlanSeason.lowSeasonThree.to, true) }}</ln-col>
              </ln-col>
            </ln-col>
          </ln-row>
          <ln-row space>
            <ln-col wrap>
              <table>
                <tr>
                  <td>Preis pro Nacht bei Personenzahl</td>
                  <td>2</td>
                  <td>3</td>
                  <td>4/5</td>
                </tr>
                <tr>
                  <td>Vorsaison / Nachsaison</td>
                  <td>45,00,-</td>
                  <td>50,00,-</td>
                  <td>55,00,-</td>
                </tr>
                <tr>
                  <td>Nebensaison</td>
                  <td>55,00,-</td>
                  <td>60,00,-</td>
                  <td>65,00,-</td>
                </tr>
                <tr>
                  <td>Hauptsaison</td>
                  <td>65,00,-</td>
                  <td>70,00,-</td>
                  <td>75,00,-</td>
                </tr>
              </table>
            </ln-col>
          </ln-row>
        </ln-col>
      </ln-row>
    </ln-row>
  </ln-container>
  <ln-dialog v-if="$store.getters.authenticated" xs=16 sm=13 md=8 lg=6 xl=5 v-model="createDialogOpen" primary-color shadow border-radius="5">
    <ln-container wrap>
      <ln-row space>
        <ln-col>
          <h3>Belegung hinzufügen</h3>
        </ln-col>
      </ln-row>
      <ln-row space class="new--booking--date--picker--wrapper">
        <input type="date" id="start" name="start" class="new--booking--date--picker"
               v-model="this.datepickerFrom">
        <input type="date" id="end" name="end" class="new--booking--date--picker"
               v-model="this.datepickerTo">
      </ln-row>
      <ln-row space justify="space-between" nowrap class="dialog--button--section">
        <ln-button interaction-color space @click="onAbortDialog()" shadow border-radius="5" width="150px" class="button">
          <span> Abbrechen </span>
        </ln-button>
        <ln-button interaction-color space @click="onSubmitNewBooking()" shadow border-radius="5" width="150px" class="button">
          <span> Speichern </span>
        </ln-button>
      </ln-row>
    </ln-container>
  </ln-dialog>
  <ln-dialog v-if="$store.getters.authenticated" xs=16 sm=13 md=8 lg=6 xl=5 v-model="deleteDialogOpen" primary-color shadow border-radius="5">
    <ln-container wrap>
      <ln-row space>
        <ln-col>
          <h3>Belegung löschen</h3>
        </ln-col>
      </ln-row>
      <ln-row space>
        <span>Buchung vom {{ selectedBooking && getReadableDate(new Date(selectedBooking.from)) }} bis {{ selectedBooking && getReadableDate(new Date(selectedBooking.to)) }} wirklich löschen?</span>
      </ln-row>
      <ln-row space justify="space-between" nowrap class="dialog--button--section">
        <ln-button interaction-color space @click="onAbortDialog()" shadow border-radius="5" width="150px" class="button">
          <span> Abbrechen </span>
        </ln-button>
        <ln-button interaction-color space @click="onSubmitDeleteBooking()" shadow border-radius="5" width="150px" class="button">
          <span> löschen </span>
        </ln-button>
      </ln-row>
    </ln-container>
  </ln-dialog>
  <ln-dialog v-if="$store.getters.authenticated" xs=16 sm=13 md=8 lg=6 xl=5 v-model="chooseDialogOpen" primary-color shadow border-radius="5">
    <ln-container wrap>
      <ln-row space>
        <ln-col>
          <h3>Belegung löschen oder neue hinzufügen?</h3>
        </ln-col>
      </ln-row>
      <ln-row space justify="space-between" nowrap class="dialog--button--section">
        <ln-button interaction-color space @click="onAbortDialog()" shadow border-radius="5" width="150px" class="button">
          <span> Abbrechen </span>
        </ln-button>
        <ln-button interaction-color space @click="onSubmitChooseAction(false)" shadow border-radius="5" width="150px" class="button">
          <span> Löschen </span>
        </ln-button>
        <ln-button interaction-color space @click="onSubmitChooseAction(true)" shadow border-radius="5" width="150px" class="button">
          <span> Hinzufügen </span>
        </ln-button>
      </ln-row>
    </ln-container>
  </ln-dialog>
  <edit-seasons v-model="editSeasonsDialogOpen" v-if="$store.getters.authenticated"/>
</template>

<script>
import {DELETE_BOOKING, FETCH_BOOKINGS, OCCUPANCY_PLAN_MONTH_OFFSET_CHANGED, POST_BOOKING, FETCH_SEASONS} from "@/store";
import EditSeasons from "@/components/seasonAdministration/EditSeasons";
export default {
  name: "OccupancyPlan",
  components: {EditSeasons},
  data () {
    return {
      currentDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
      createDialogOpen: false,
      deleteDialogOpen: false,
      chooseDialogOpen: false,
      editSeasonsDialogOpen: false,
      datepickerFrom: null,
      datepickerTo: null,
      selectedBooking: null,
      selectedDate: null
    }
  },
  mounted() {
    this.updateSelectedDate(this.occupancySelectedMonthOffset)
    this.$store.dispatch(FETCH_BOOKINGS)
    this.$store.dispatch(FETCH_SEASONS)
  },
  computed: {
    occupancyPlanBookings() {
      return this.$store.getters.occupancyPlanBookings
    },
    occupancyPlanSeasons() {
      return this.$store.getters.occupancyPlanSeasons
    },
    occupancySelectedMonthOffset() {
      return this.$store.getters.occupancySelectedMonthOffset
    },
    currentOccupancyPlanSeason() {
      let currentOccupancyPlanSeason = null
      if (this.$store.getters.occupancyPlanSeasons.length > 0) {
        currentOccupancyPlanSeason = this.$store.getters.occupancyPlanSeasons.filter((season) => season.year === this.currentDate.getFullYear())[0]
      }
      if (!currentOccupancyPlanSeason) {
        return {
          preSeason: {
            from: null,
            to: null
          },
          lowSeasonOne: {
            from: null,
            to: null
          },
          peakSeason: {
            from: null,
            to: null
          },
          lowSeasonTwo: {
            from: null,
            to: null
          },
          postSeason: {
            from: null,
            to: null
          },
          lowSeasonThree: {
            from: null,
            to: null
          }
        }
      }
      return currentOccupancyPlanSeason
    }
  },
  watch: {
    occupancySelectedMonthOffset() {
      this.updateSelectedDate()
    },
    datepickerTo() {
      if (this.datepickerTo < this.datepickerFrom) {
        this.datepickerFrom = this.datepickerTo
      }
    },
    datepickerFrom() {
      if (this.datepickerTo < this.datepickerFrom) {
        this.datepickerTo = this.datepickerFrom
      }
    }
  },
  methods: {
    getMonthPerNumber(month) {
      switch (month) {
        case(-1): return 'Dez'
        case(0): return 'Jan'
        case(1): return 'Feb'
        case(2): return 'März'
        case(3): return 'Apr'
        case(4): return 'Mai'
        case(5): return 'Jun'
        case(6): return 'Jul'
        case(7): return 'Aug'
        case(8): return 'Sept'
        case(9): return 'Okt'
        case(10): return 'Nov'
        case(11): return 'Dez'
        case(12): return 'Jan'
      }
    },
    getReadableDate(date, short=false) {
      if (!date) return ''
      if (short) return `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear().toString().slice(-2)}`
      return `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear().toString()}`
    },
    getDateForDatepicker(date) {
      if (!date) return ''
      return `${date.getFullYear().toString()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`
    },
    updateSelectedDate() {
      this.currentDate = new Date(new Date().getFullYear(), new Date().getMonth() + this.occupancySelectedMonthOffset, 1)
    },
    updateMonthOffset(offset) {
      this.$store.commit(OCCUPANCY_PLAN_MONTH_OFFSET_CHANGED, this.occupancySelectedMonthOffset + offset)
    },
    mapBookingsToCalendar(date) {
      const classes = ['calender--day']
      for (let i = 0; i < this.occupancyPlanBookings.length; i++) {
        if (date.getTime() >= this.occupancyPlanBookings[i].from && date.getTime() <= this.occupancyPlanBookings[i].to.getTime()) {
          if (this.occupancyPlanBookings[i].from.getTime() === date.getTime() || this.occupancyPlanBookings[i].to.getTime() === date.getTime()) {
            classes.push('calendar--day--is--arrival')
          } else {
            classes.push('calendar--day--is--booked')
          }
        }
      }
      return classes
    },
    onDayClicked(date) {
      this.selectedDate = date
      this.selectedBooking = null
      if (this.$store.getters.authenticated) {
        for (let i = 0; i < this.occupancyPlanBookings.length; i++) {
          if (date.getTime() >= this.occupancyPlanBookings[i].from && date.getTime() <= this.occupancyPlanBookings[i].to.getTime()) {
            this.selectedBooking = this.occupancyPlanBookings[i]
            break
          }
        }
        if (this.selectedBooking) {
          if (this.selectedBooking.from.getTime() === date.getTime() || this.selectedBooking.to.getTime() === date.getTime()) {
            this.chooseDialogOpen = true
          } else {
            this.deleteDialogOpen = true
          }
        } else {
          this.datepickerFrom = this.getDateForDatepicker(date)
          this.datepickerTo = this.getDateForDatepicker(date)
          this.createDialogOpen = true
        }
      }
    },
    onAbortDialog: function() {
      this.createDialogOpen = false
      this.deleteDialogOpen = false
      this.chooseDialogOpen = false
    },
    onSubmitNewBooking: function() {
      this.$store.dispatch(POST_BOOKING, {
        'from': new Date(this.datepickerFrom),
        'to': new Date(this.datepickerTo),
      })
      this.updateSelectedDate(this.occupancySelectedMonthOffset)
      this.createDialogOpen = false
    },
    onSubmitDeleteBooking: function() {
      this.$store.dispatch(DELETE_BOOKING, this.selectedBooking)
      this.deleteDialogOpen = false
    },
    onSubmitChooseAction: function(action) {
      this.chooseDialogOpen = false
      if (action === true) {
        this.datepickerFrom = this.getDateForDatepicker(this.selectedDate)
        this.datepickerTo = this.getDateForDatepicker(this.selectedDate)
        this.createDialogOpen = true
      } else {
        this.deleteDialogOpen = true
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../style/variables';
.calender--wrapper {
}

.calendar--week {
}

.calender--day {
  @media screen and (max-width: 600px) {
    height: 60px;
  }
  @media screen and (min-width: 600px) and (max-width: 960px) {
    height: 70px;
  }
  @media screen and (min-width: 960px) and (max-width: 1264px) {
    height: 80px;
  }
  @media screen and (min-width: 1264px) and (max-width: 1904px) {
    height: 90px;
  }
  @media screen and (min-width: 1904px) {
    height: 100px;
  }
  justify-content: left;
  align-items: start;
  > span {
    margin: 10px;
  }
  &.calender--day--grey--out {
    filter: brightness(0.85);
  }
  &.calendar--day--is--booked {
    background-color: grey;
    filter: brightness(1);
  }
  &.calendar--day--is--arrival {
    background-color: #A1C2D5;
    filter: brightness(1);
  }
}

.dialog--button--section {
  margin-top: 50px;
}

.new--booking--date--picker {
  width: 50%;
  margin: 10px;
}

.new--booking--date--picker--wrapper {
  display: flex;
  flex-flow: nowrap;
  justify-content: space-evenly;
}

::-webkit-datetime-edit-fields-wrapper { padding-left: 0.5em; }
::-webkit-datetime-edit-text {  }
::-webkit-datetime-edit-month-field {  }
::-webkit-datetime-edit-day-field {  }
::-webkit-datetime-edit-year-field {  }
::-webkit-inner-spin-button {  }
::-webkit-calendar-picker-indicator { padding-right: 0.5em; }

.font--bold {
  font-weight: bold;
}
</style>