<template>
  <ln-app>
    <ln-header shadow height="50px" primary-color>
      <ln-container>
        <ln-row align="center">
          <ln-icon hide-in="md lg xl" width="50" height="50" @click="burgerMenuOpen = !burgerMenuOpen">menu</ln-icon>
          <ln-col hide-in="xs sm" justify="center" class="burger-menu_tile" height="50px" v-for="route in mainMenuRoutes" v-bind:key=route.name @click="() => { this.$router.push(route) }">
            {{ route.meta.displayName }}
          </ln-col>
        </ln-row>
      </ln-container>
    </ln-header>
    <ln-burger-menu shadow width="250px" primary-color v-model="burgerMenuOpen">
      <ln-container>
        <ln-row wrap>
          <ln-col justify="center" class="burger-menu_tile" height="50px" v-for="route in mainMenuRoutes" v-bind:key=route.name @click="() => { this.burgerMenuOpen = false; this.$router.push(route) }">
            {{ route.meta.displayName }}
          </ln-col>
        </ln-row>
      </ln-container>
    </ln-burger-menu>
    <router-view />
    <ln-footer height="30px" shadow primary-color>
      <ln-container shadow>
        <ln-row>
          <ln-col xs=5 sm=3 md=2 lg=2 xl=2 height="30px" justify="center" v-for="route in footerMenuRoutes"
                        v-bind:key=route.name
                        v-on:click="() => { this.$router.push(route) }" class="burger-menu_tile">
            {{ route.meta.displayName }}
          </ln-col>
        </ln-row>
      </ln-container>
    </ln-footer>
  </ln-app>
</template>

<script>
import {LnBurgerMenu} from "@ln-cloud/ln-materials";

export default {
  name: 'App',
  components: {LnBurgerMenu },
  data() {
    return {
      burgerMenuOpen: false,
      mainMenuRoutes: this.$router.getRoutes().filter((x) =>
          x.meta.showMenu && (this.$store.getters.authenticated ? x.meta.requireGuest === false : x.meta.requireAuth === false)),

      footerMenuRoutes: this.$router.getRoutes().filter((x) =>
          x.meta.showFooter && (this.$store.getters.authenticated ? x.meta.requireGuest === false : x.meta.requireAuth === false))
    }
  }
}
</script>

<style lang="scss">
@import './style/variables.scss';
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  justify-content: center;
}

@font-face {
  font-family: "Montserrat";
  src: url(../src/fonts/Montserrat-Light.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

html, body {
  font-size: $font-size;
  background-image: url('https://api.files.ln-cloud.de/image/large/74e596bd-6063-47ea-aa1f-22ba5a8b8271');
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  height: 100%;
  -webkit-text-size-adjust: 100%;
  overflow: auto;
  margin: 0;
}

body .element {
  position:fixed;
}

.burger-menu_tile {
  align-items: center;
  cursor: pointer;
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: white;
      filter: invert(1)
    }
  }
}
.button {
  background-color: white;
  filter: invert(1);
}
</style>
