<template>
  <ln-container sm=12 md=10 lg=10 xl=10 row space color-accent class="content-container">
    <ln-row wrap primary-color shadow border-radius="5">
      <ln-col wrap space xs=16 sm=16 md=16 lg=6 xl=6>
        <ln-row>
          <p>Unsere Ferienwohnung befindet sich in dem Wohngebiet "Mühlenblick" in einem Haus mit 4 Wohnungen und liegt zentral, doch trotzdem ruhig und mit eigener Zufahrt.
            Der Museumshafen, Einkaufsmöglichkeiten, die Kurverwaltung und der Strand sind zu Fuß zu erreichen.
            Unsere Nichtraucherwohnung befindet sich im Erdgeschoss, ist ca. 56 qm groß und geeignet für bis zu 5 Personen. Außerdem ist sie Allergiker geeignet (Laminatböden), daher sind Haustiere nicht erlaubt.</p>
        </ln-row>
        <ln-row align="center">
          <ln-icon>map-marker</ln-icon><span>Mühlenblick 11, 26409 Carolinensiel</span>
        </ln-row>
      </ln-col>
      <ln-col space xs=16 sm=16 md=16 lg="10" xl=10>
        <iframe
            id="map-1389198444539"
            src="//maps.google.com/maps?z=8&amp;ll=53.69531%2C7.66391089843752&amp;q=M%C3%BChlenblick%2011%2C%2026409%20Wittmund%2C%20Deutschland&amp;iwloc=near&amp;t=h&amp;f=q&amp;source=s_q&amp;hl=de&amp;ie=UTF8&amp;output=embed"
            width="100%"
            height="500"
            frameborder="0" />
      </ln-col>
    </ln-row>
  </ln-container>
</template>

<script>
export default {
  name: "LocationPage"
}
</script>

<style scoped lang="scss">
@import '../../style/variables';
</style>