import { createApp } from 'vue'
import App from './App.vue'
import router from "@/router";
import store from "@/store";
import keycloakPlugin from "@/keycloak-plugin";
import { LnMaterialsPlugin } from "@ln-cloud/ln-materials";

export const app = createApp(App)
app.use(router)
app.use(store)
app.use(keycloakPlugin)
app.use(LnMaterialsPlugin, {
    primaryColor: '#f2f2f2',
    accentColor: '#f2f2f2',
    interactionColor: '#000000',
    textColor: '#000000',
    fontWeight: 'lighter',
    fontFamily: '"Montserrat", "GothamMedium", Avenir, Helvetica, Arial, sans-serif'
})
app.config.globalProperties.$keycloak.init({
    onLoad: 'check-sso',
    checkLoginIframe: false,
    scope: 'openid profile email offline_access',
    responseType: 'code',
}).then(() => {
    app.mount('#app')
})
