import Vuex from 'vuex'
import {app} from "@/main";
import axios from "axios";

export const PERFORM_LOGIN = 'PERFORM_LOGIN'
export const LOGIN_CALLBACK = 'LOGIN_CALLBACK'
export const PERFORM_LOGOUT = 'PERFORM_LOGOUT'
export const UPDATE_USER_DATA = 'UPDATE_USER_DATA'
export const SILENT_RENEW = 'SILENT_RENEW'
export const MENU_STATE_CHANGED = 'MENU_STATE_CHANGED'
export const OCCUPANCY_PLAN_MONTH_OFFSET_CHANGED = 'OCCUPANCY_PLAN_MONTH_OFFSET_CHANGED'
export const UPDATE_AUTHENTICATED_STATUS = 'UPDATE_AUTHENTICATED_STATUS'
export const FETCH_BOOKINGS = 'FETCH_BOOKINGS'
export const FETCH_SEASONS = 'FETCH_SEASONS'
export const REMOVE_FROM_SEASON_LIST = 'REMOVE_FROM_SEASON_LIST'
export const ADD_TO_SEASON_LIST = 'ADD_TO_SEASON_LIST'
export const POST_SEASON = 'POST_SEASON'
export const DELETE_SEASON = 'DELETE_SEASON'
export const UPDATE_SEASONS = 'UPDATE_SEASONS'
export const POST_BOOKING = 'POST_BOOKING'
export const DELETE_BOOKING = 'DELETE_BOOKING'
export const UPDATE_BOOKING_LIST = 'UPDATE_BOOKING_LIST'
export const INSERT_TO_BOOKING_LIST = 'INSERT_TO_BOOKING_LIST'
export const REMOVE_FROM_BOOKING_LIST = 'REMOVE_FROM_BOOKING_LIST'
export const DIALOG_OPENED = 'DIALOG_OPENED'

const initialState = {
    userData: {
        profile: {
            email: null,
            email_verified: null,
            family_name: null,
            given_name: null,
            name: null,
            preferred_username: null,
            sub: null
        },
        authenticated: false,
    },
    pageControlling: {
        dialogs: {
            open: false
        },
        mainManu: {
            open: false,
            routes: []
        },
        occupancyPlan: {
            selectedMonthOffset: 0
        }
    },
    occupancyPlan: {
        bookings: [],
        seasons: []
    }
}

export default new Vuex.Store({
    state: initialState,
    getters: {
        authenticated: state => state.userData.authenticated,
        userProfile: state => state.userData.profile,
        menuOpen: state => state.pageControlling.mainManu.open,
        objectInForeground: state => {
            return state.pageControlling.dialogs.open || state.pageControlling.mainManu.open
        },
        occupancySelectedMonthOffset: state => state.pageControlling.occupancyPlan.selectedMonthOffset,
        occupancyPlanBookings: state => state.occupancyPlan.bookings,
        occupancyPlanSeasons: state => state.occupancyPlan.seasons,
    },
    mutations: {
        [UPDATE_USER_DATA](state, userData) {
            state.userData.profile = userData
        },
        [MENU_STATE_CHANGED](state, menuState) {
            state.pageControlling.mainManu.open = menuState
        },
        [OCCUPANCY_PLAN_MONTH_OFFSET_CHANGED](state, offset) {
            state.pageControlling.occupancyPlan.selectedMonthOffset = offset
        },
        [UPDATE_AUTHENTICATED_STATUS](state, authenticated) {
            state.userData.authenticated = authenticated
        },
        [UPDATE_BOOKING_LIST](state, bookings) {
            state.occupancyPlan.bookings = bookings.map((booking) => {
                return {
                    id: booking.id,
                    from: new Date(new Date(booking.from).getFullYear(), new Date(booking.from).getMonth(), new Date(booking.from).getDate()),
                    to: new Date(new Date(booking.to).getFullYear(), new Date(booking.to).getMonth(), new Date(booking.to).getDate())
                }
            })
        },
        [UPDATE_SEASONS](state, seasons) {
            state.occupancyPlan.seasons = seasons.map((season) => {
                return {
                    id: season.id,
                    year: season.year,
                    preSeason: {
                        from: new Date(new Date(season.preSeason.from).getFullYear(), new Date(season.preSeason.from).getMonth(), new Date(season.preSeason.from).getDate()),
                        to: new Date(new Date(season.preSeason.to).getFullYear(), new Date(season.preSeason.to).getMonth(), new Date(season.preSeason.to).getDate())
                    },
                    lowSeasonOne: {
                        from: new Date(new Date(season.lowSeasonOne.from).getFullYear(), new Date(season.lowSeasonOne.from).getMonth(), new Date(season.lowSeasonOne.from).getDate()),
                        to: new Date(new Date(season.lowSeasonOne.to).getFullYear(), new Date(season.lowSeasonOne.to).getMonth(), new Date(season.lowSeasonOne.to).getDate())
                    },
                    peakSeason: {
                        from: new Date(new Date(season.peakSeason.from).getFullYear(), new Date(season.peakSeason.from).getMonth(), new Date(season.peakSeason.from).getDate()),
                        to: new Date(new Date(season.peakSeason.to).getFullYear(), new Date(season.peakSeason.to).getMonth(), new Date(season.peakSeason.to).getDate())
                    },
                    lowSeasonTwo: {
                        from: new Date(new Date(season.lowSeasonTwo.from).getFullYear(), new Date(season.lowSeasonTwo.from).getMonth(), new Date(season.lowSeasonTwo.from).getDate()),
                        to: new Date(new Date(season.lowSeasonTwo.to).getFullYear(), new Date(season.lowSeasonTwo.to).getMonth(), new Date(season.lowSeasonTwo.to).getDate())
                    },
                    postSeason: {
                        from: new Date(new Date(season.postSeason.from).getFullYear(), new Date(season.postSeason.from).getMonth(), new Date(season.postSeason.from).getDate()),
                        to: new Date(new Date(season.postSeason.to).getFullYear(), new Date(season.postSeason.to).getMonth(), new Date(season.postSeason.to).getDate())
                    },
                    lowSeasonThree: {
                        from: new Date(new Date(season.lowSeasonThree.from).getFullYear(), new Date(season.lowSeasonThree.from).getMonth(), new Date(season.lowSeasonThree.from).getDate()),
                        to: new Date(new Date(season.lowSeasonThree.to).getFullYear(), new Date(season.lowSeasonThree.to).getMonth(), new Date(season.lowSeasonThree.to).getDate())
                    }
                }
            })
        },
        [ADD_TO_SEASON_LIST](state, season) {
            state.occupancyPlan.seasons = state.occupancyPlan.seasons.concat([{
                id: season.id,
                year: season.year,
                preSeason: {
                    from: new Date(new Date(season.preSeason.from).getFullYear(), new Date(season.preSeason.from).getMonth(), new Date(season.preSeason.from).getDate()),
                    to: new Date(new Date(season.preSeason.to).getFullYear(), new Date(season.preSeason.to).getMonth(), new Date(season.preSeason.to).getDate())
                },
                lowSeasonOne: {
                    from: new Date(new Date(season.lowSeasonOne.from).getFullYear(), new Date(season.lowSeasonOne.from).getMonth(), new Date(season.lowSeasonOne.from).getDate()),
                    to: new Date(new Date(season.lowSeasonOne.to).getFullYear(), new Date(season.lowSeasonOne.to).getMonth(), new Date(season.lowSeasonOne.to).getDate())
                },
                peakSeason: {
                    from: new Date(new Date(season.peakSeason.from).getFullYear(), new Date(season.peakSeason.from).getMonth(), new Date(season.peakSeason.from).getDate()),
                    to: new Date(new Date(season.peakSeason.to).getFullYear(), new Date(season.peakSeason.to).getMonth(), new Date(season.peakSeason.to).getDate())
                },
                lowSeasonTwo: {
                    from: new Date(new Date(season.lowSeasonTwo.from).getFullYear(), new Date(season.lowSeasonTwo.from).getMonth(), new Date(season.lowSeasonTwo.from).getDate()),
                    to: new Date(new Date(season.lowSeasonTwo.to).getFullYear(), new Date(season.lowSeasonTwo.to).getMonth(), new Date(season.lowSeasonTwo.to).getDate())
                },
                postSeason: {
                    from: new Date(new Date(season.postSeason.from).getFullYear(), new Date(season.postSeason.from).getMonth(), new Date(season.postSeason.from).getDate()),
                    to: new Date(new Date(season.postSeason.to).getFullYear(), new Date(season.postSeason.to).getMonth(), new Date(season.postSeason.to).getDate())
                },
                lowSeasonThree: {
                    from: new Date(new Date(season.lowSeasonThree.from).getFullYear(), new Date(season.lowSeasonThree.from).getMonth(), new Date(season.lowSeasonThree.from).getDate()),
                    to: new Date(new Date(season.lowSeasonThree.to).getFullYear(), new Date(season.lowSeasonThree.to).getMonth(), new Date(season.lowSeasonThree.to).getDate())
                }
            }])
        },
        [REMOVE_FROM_SEASON_LIST](state, seasonId) {
            state.occupancyPlan.seasons = state.occupancyPlan.seasons.filter((season) => {return season.id !== seasonId})
        },
        [INSERT_TO_BOOKING_LIST](state, booking) {
            state.occupancyPlan.bookings = state.occupancyPlan.bookings.concat([{
                id: booking.id,
                from: new Date(new Date(booking.from).getFullYear(), new Date(booking.from).getMonth(), new Date(booking.from).getDate()),
                to: new Date(new Date(booking.to).getFullYear(), new Date(booking.to).getMonth(), new Date(booking.to).getDate())
            }])
        },
        [REMOVE_FROM_BOOKING_LIST](state, booking) {
            state.occupancyPlan.bookings = state.occupancyPlan.bookings.filter((x) => {return x.id !== booking.id})
        },
        [DIALOG_OPENED](state, dialogOpen) {
            state.pageControlling.dialogs.open = dialogOpen
        }
    },
    actions: {
        async [PERFORM_LOGIN](commit, url) {
            app.config.globalProperties.$keycloak.login({
                redirectUri: process.env.VUE_APP_HOST_URI + url.path
            })
        },
        async [LOGIN_CALLBACK]({ commit }) {
            app.config.globalProperties.$keycloak.onAuthSuccess = function() {
                commit(UPDATE_AUTHENTICATED_STATUS, app.config.globalProperties.$keycloak.authenticated)
                app.config.globalProperties.$keycloak.loadUserInfo().then(function (userInfo) {
                    commit(UPDATE_USER_DATA, userInfo)
                })
            }
        },
        async [PERFORM_LOGOUT](commit, url) {
            app.config.globalProperties.$keycloak.logout({
                redirectUri: process.env.VUE_APP_HOST_URI + url.path
            })
        },
        async [SILENT_RENEW]({ commit }) {
            console.log(commit)
        },
        async [FETCH_BOOKINGS]({ commit }) {
            axios
                .get(process.env.VUE_APP_API_URI)
                .then(response => {
                    commit(UPDATE_BOOKING_LIST, response.data)
                })
        },
        async [FETCH_SEASONS]({ commit }) {
            axios
                .get(process.env.VUE_APP_API_URI + '/seasons')
                .then(response => {
                    commit(UPDATE_SEASONS, response.data)
                })
        },
        async [POST_SEASON]({ commit }, season) {
            axios
                .post(process.env.VUE_APP_API_URI + '/seasons', {
                    year: season.year,
                    preSeason: season.preSeason,
                    lowSeasonOne: season.lowSeasonOne,
                    peakSeason: season.peakSeason,
                    lowSeasonTwo: season.lowSeasonTwo,
                    postSeason: season.postSeason,
                    lowSeasonThree: season.lowSeasonThree
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + app.config.globalProperties.$keycloak.token
                    }
                })
                .then(response => {
                    commit(ADD_TO_SEASON_LIST, response.data)
                })
        },
        async [DELETE_SEASON]({ commit }, seasonId) {
            axios
                .delete(process.env.VUE_APP_API_URI + '/seasons', {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + app.config.globalProperties.$keycloak.token
                    },
                    data: {
                        'id': seasonId
                    }
                }).then(() => {
                commit(REMOVE_FROM_SEASON_LIST, seasonId)
            })
        },
        async [POST_BOOKING]({ commit }, booking) {
            axios
                .post(process.env.VUE_APP_API_URI, {
                    'from': booking.from,
                    'to': booking.to
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + app.config.globalProperties.$keycloak.token
                    }
                }).then(response => {
                    commit(INSERT_TO_BOOKING_LIST, response.data)
            })
        },
        async [DELETE_BOOKING]({ commit }, booking) {
            axios
                .delete(process.env.VUE_APP_API_URI, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + app.config.globalProperties.$keycloak.token
                    },
                    data: {
                        'id': booking.id
                    }
                }).then(() => {
                    commit(REMOVE_FROM_BOOKING_LIST, booking)
            })
        },
    }
})