<template>
  <ln-dialog z-index="110" xs="16" sm="11" md="8" lg="6" xl="4" v-model="dialogOpen" primary-color shadow border-radius="5">
    <ln-container wrap>
      <ln-row wrap>
        <ln-row align="center" space>
          <ln-col xs="5" sm="4" md="3" lg="3" xl="3">
            <span>Jahr:</span>
          </ln-col>
          <ln-col justify="end">
            <ln-combobox :items="getNextTenYears()" primary-color v-model="newSeason.year"/>
          </ln-col>
        </ln-row>
        <ln-col align="center" space class="new--booking--date--picker--wrapper">
          <ln-col xs="5" sm="4" md="3" lg="3" xl="3">Vorsaison: </ln-col>
          <ln-col justify="end">
            <input type="date" id="start" name="start" class="new--booking--date--picker"
                   v-model="this.newSeason.preSeason.from">
            <input type="date" id="end" name="end" class="new--booking--date--picker"
                   v-model="this.newSeason.preSeason.to">
          </ln-col>
        </ln-col>
        <ln-col align="center" space class="new--booking--date--picker--wrapper">
          <ln-col xs="5" sm="4" md="3" lg="3" xl="3">Nebensaison: </ln-col>
          <ln-col justify="end">
            <input type="date" id="start" name="start" class="new--booking--date--picker"
                   v-model="this.newSeason.lowSeasonOne.from">
            <input type="date" id="end" name="end" class="new--booking--date--picker"
                   v-model="this.newSeason.lowSeasonOne.to">
          </ln-col>
        </ln-col>
        <ln-col align="center" space class="new--booking--date--picker--wrapper">
          <ln-col xs="5" sm="4" md="3" lg="3" xl="3">Hauptsaison: </ln-col>
          <ln-col justify="end">
            <input type="date" id="start" name="start" class="new--booking--date--picker"
                   v-model="this.newSeason.peakSeason.from">
            <input type="date" id="end" name="end" class="new--booking--date--picker"
                   v-model="this.newSeason.peakSeason.to">
          </ln-col>
        </ln-col>
        <ln-col align="center" space class="new--booking--date--picker--wrapper">
          <ln-col xs="5" sm="4" md="3" lg="3" xl="3">Nebensaison: </ln-col>
          <ln-col justify="end">
            <input type="date" id="start" name="start" class="new--booking--date--picker"
                   v-model="this.newSeason.lowSeasonTwo.from">
            <input type="date" id="end" name="end" class="new--booking--date--picker"
                   v-model="this.newSeason.lowSeasonTwo.to">
          </ln-col>
        </ln-col>
        <ln-col align="center" space class="new--booking--date--picker--wrapper">
          <ln-col xs="5" sm="4" md="3" lg="3" xl="3">Nachsaison: </ln-col>
          <ln-col justify="end">
            <input type="date" id="start" name="start" class="new--booking--date--picker"
                   v-model="this.newSeason.postSeason.from">
            <input type="date" id="end" name="end" class="new--booking--date--picker"
                   v-model="this.newSeason.postSeason.to">
          </ln-col>
        </ln-col>
        <ln-col align="center" space class="new--booking--date--picker--wrapper">
          <ln-col xs="5" sm="4" md="3" lg="3" xl="3">Nebensaison: </ln-col>
          <ln-col justify="end">
            <input type="date" id="start" name="start" class="new--booking--date--picker"
                   v-model="this.newSeason.lowSeasonThree.from">
            <input type="date" id="end" name="end" class="new--booking--date--picker"
                   v-model="this.newSeason.lowSeasonThree.to">
          </ln-col>
        </ln-col>
      </ln-row>
      <ln-row space justify="space-between" nowrap class="dialog--button--section">
        <ln-button interaction-color space @click="dialogOpen = false" shadow border-radius="5" width="150px" class="button">
          <span> Abbrechen </span>
        </ln-button>
        <ln-button interaction-color space @click="onSubmitNewSeason()" shadow border-radius="5" width="150px" class="button">
          <span> Speichern </span>
        </ln-button>
      </ln-row>
    </ln-container>
  </ln-dialog>
</template>

<script>
import {POST_SEASON} from "@/store";

export default {
  name: "CreateSeason",
  data() {
    return {
      dialogOpen: false,
      newSeason: {
        year: null,
        preSeason: {
          from: null,
          to: null
        },
        lowSeasonOne: {
          from: null,
          to: null
        },
        peakSeason: {
          from: null,
          to: null
        },
        lowSeasonTwo: {
          from: null,
          to: null
        },
        postSeason: {
          from: null,
          to: null
        },
        lowSeasonThree: {
          from: null,
          to: null
        }
      }
    }
  },
  props: {
    modelValue: {
      type: Boolean
    }
  },
  watch: {
    dialogOpen() {
      this.$emit('update:modelValue', this.dialogOpen)
    },
    modelValue() {
      this.dialogOpen = this.modelValue
    }
  },
  methods: {
    onSubmitNewSeason: function() {
      this.$store.dispatch(POST_SEASON, this.newSeason)
      this.dialogOpen = false
    },
    getNextTenYears() {
      const years = []
      for (let i = new Date().getFullYear(); i <= new Date().getFullYear() + 5; i++) {
        years.push(i)
      }
      return years
    }
  }
}
</script>

<style scoped>

</style>