<template>
  <ln-container sm=12 md=10 lg=10 xl=10 row space>
    <ln-row wrap space primary-color border-radius="5" shadow>
      <ln-col class="prices--content--container">
        <h3>Preise / Leistung</h3>
      </ln-col>
      <ln-col class="prices--content--container">
        <table>
          <tr>
            <td>Preis pro Nacht bei Personenzahl</td>
            <td>2</td>
            <td>3</td>
            <td>4/5</td>
          </tr>
          <tr>
            <td>Vorsaison / Nachsaison</td>
            <td>45,00,-</td>
            <td>50,00,-</td>
            <td>55,00,-</td>
          </tr>
          <tr>
            <td>Nebensaison</td>
            <td>55,00,-</td>
            <td>60,00,-</td>
            <td>65,00,-</td>
          </tr>
          <tr>
            <td>Hauptsaison</td>
            <td>65,00,-</td>
            <td>70,00,-</td>
            <td>75,00,-</td>
          </tr>
        </table>
      </ln-col>
      <ln-col class="prices--content--container">
        <p>
          In den Preisen sind die Nebenkosten für Strom, Wasser und Heizung enthalten.
          Bettwäsche, Geschirrtücher und Handtücher bringen Sie bitte mit.
          Wird das Schlafsofa benutzt, benötigen Sie ein breites Bettlaken (ca. 1,3m).
          Buchungen während der Schulferien nur wochenweise, Anreise nur samstags oder nach Absprache.
          Der Kurbeitrag beträgt 3,00€ für Erwachsene (ab 16 Jahren) und 1,50€ für Kinder ab 4 Jahren.
        </p>
      </ln-col>
      <ln-col class="prices--content--container">
        <p>
          Der Mieter darf jederzeit vom Mietvertrag zurückzutreten. Im Falle eines solchen Rücktritts
          wird vom Vermieter eine Ausfallentschädigung nach folgender Staffelung geltend gemacht:
        </p>
      </ln-col>
      <ln-col class="prices--content--container">
        <p>
          Rücktritt bis<br>
          28 Tage vor Mietbeginn kostenlos<br>
          27 – 8 Tage vor Mietbeginn 80 % des Mietpreises<br>
          7 Tage vor Mietbeginn  90 % des Mietpreises<br>
        </p>
      </ln-col>
      <ln-col class="prices--content--container">
        <p>
          Wenn wir als Vermieter Ersatz finden, werden die Stornokosten erstattet.
          Zu Ihrer Buchung empfehlen wir Ihnen den Abschluss einer Reiseversicherung.
          Ereignisse wie Krankheit, Unfall oder andere unerwünschte Vorkommnisse,
          auch innerhalb der Familie können unvorhergesehen eintreten.
        </p>
      </ln-col>
    </ln-row>
  </ln-container>
</template>

<script>
export default {
  name: "PricesPage"
}
</script>

<style scoped lang="scss">
@import '../../style/variables';
.prices--content--container {
  display: flex;
  justify-content: start;
  > p {
    text-align: left;
  }
  > ul {
    text-align: left;
    margin-left: -15px;
  }
  > table {
    text-align: left;
  }
}

</style>