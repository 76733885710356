<template>
  <ln-container space wrap sm=12 md=10 lg=10 xl=10>
    <ln-row primary-color wrap border-radius="5" shadow>
      <ln-col space>
        <h4>Kontakt</h4>
      </ln-col>
      <ln-col space class="contact--content--container">
        <p>
          Wir stehen Ihnen gerne zur Verfügung!
        </p>
      </ln-col>
      <ln-col space class="contact--content--container">
        <p>
          Nehmen Sie doch einfach Kontakt mit uns auf. Zu speziellen Einzelheiten geben wir gerne nähere Auskünfte,
          bei individuellen Anliegen finden wir eine gemeinsame Lösung. In jedem Fall stehen wir Ihnen mit Rat,
          Tat und Antworten zur Seite.
        </p>
      </ln-col>
      <ln-col space class="contact--content--container">
        <p>
          Persönlich am Telefon:
          <span class="font--bold">05254/69155</span>
        </p>
      </ln-col>
      <ln-col space class="contact--content--container">
        <p>
          oder
        </p>
      </ln-col>
      <ln-col space class="contact--content--container">
        <p>
          per EMail unter:
          <a href="mailto:schaeffer-elsen@arcor.de">
            <span class="font--bold">schaeffer-elsen@arcor.de</span>
          </a>
        </p>
      </ln-col>
      <ln-col space class="contact--content--container">
        <p>
          Michael und Margitta Schäffer
          Nesthauser Str. 64e
          33106 Paderborn
        </p>
      </ln-col>
      <ln-col space class="contact--content--container">
        <p>
          Hinweis zur Online-Streitbeilegung gemäß Art. 14 Abs. 1 ODR-VO:
          Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit,
          die Sie unter
          <a href="https://ec.europa.eu/consumers/odr/" target="_blank">ec.europa.eu</a> finden.
        </p>
      </ln-col>
    </ln-row>
  </ln-container>
</template>

<script>
export default {
  name: "ContactPage",
}
</script>

<style scoped lang="scss">
.contact--content--container {
  display: flex;
  justify-content: start;
  > p {
    text-align: left;
  }
  > ul {
    text-align: left;
    margin-left: -15px;
  }
}
.font--bold {
  font-weight: bold;
}
</style>